const imperialUnits = [
  '',
  'Teaspoon',
  'Teaspoons',
  'Tablespoon',
  'Tablespoons',
  'Cup',
  'Cups',
  'Ounce',
  'Ounces',
  'Pound',
  'Pounds',
  'Gram',
  'Grams',
  'Gallon',
  'Gallons',
  'Pinch',
  'Each',
  'As needed',
  'To serve',
  'To Taste',
  'Bunch',
  'Bunches',
  'Can',
  'Cans',
  'Clove',
  'Cloves',
  'Leaf',
  'Leaves',
  'Package',
  'Packages',
  'Recipe',
  'Recipes',
  'Rib',
  'Ribs',
  'Slice',
  'Slices',
  'Sprig',
  'Sprigs',
  'Wedge',
  'Wedges',
  'Head',
  'Heads'
].sort();

const metricUnits = [
  '',
  'Teaspoon',
  'Teaspoons',
  'Tablespoon',
  'Tablespoons',
  'Milliliter',
  'Milliliters',
  'Liter',
  'Liters',
  'Ounce',
  'Ounces',
  'Gram',
  'Grams',
  'Kilo',
  'Kilos',
  'Pinch',
  'Each',
  'As needed',
  'To serve',
  'To Taste',
  'Bunch',
  'Bunches',
  'Can',
  'Cans',
  'Clove',
  'Cloves',
  'Leaf',
  'Leaves',
  'Package',
  'Packages',
  'Recipe',
  'Recipes',
  'Rib',
  'Ribs',
  'Slice',
  'Slices',
  'Sprig',
  'Sprigs',
  'Wedge',
  'Wedges',
  'Head',
  'Heads'
].sort();

export { imperialUnits, metricUnits };
