const mockSdk = {
  field: {
    getValue: () => {
      return null;
    },
    setValue: () => {
      return null;
    }
  }
};

export default mockSdk;
