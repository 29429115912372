const locations = {
  LOCATION_ENTRY_FIELD: "entry-field",
  LOCATION_ENTRY_FIELD_SIDEBAR: "entry-field-sidebar",
  LOCATION_ENTRY_SIDEBAR: "entry-sidebar",
  LOCATION_DIALOG: "dialog",
  LOCATION_ENTRY_EDITOR: "entry-editor",
  LOCATION_PAGE: "page",
  LOCATION_APP_CONFIG: "app-config",
};

export default locations;